import React,{lazy,Suspense} from "react"
import { BrowserRouter,Switch, Route,  } from 'react-router-dom'
import ReactGA from 'react-ga4';
 /*import Home from  './components/HomePage/Home'
import Login from './components/LoginPage/Login'
import ForgotPassword from './components/LoginPage/ForgotPassword';
import ChangePassword from './components/LoginPage/ChangePassword';
import Dashboard from './components/Dashboard/Dashboard';
 import DashboardAdmin from './components/Dashboard/DashboardAdmin';
 import ManageMember from './components/Admin/Members/ManageMembers/ManageMembers';
 import EditCompanyProfile from './components/Admin/Members/MemberData/CompanyProfile';
 import ManageContent from './components/Admin/Members/ManageContent/ManageContent';
 import ManageUsers from './components/Admin/Members/ManageUsers/ManageUser';
 import UserDetails from'./components/Admin/Members/ManageUsers/UpdateUser'; 
 import ViewOffering from './components/Admin/Preview/ViewOffering';
 import ViewSuccessStory from './components/Admin/Preview/ViewSuccessStory';
 import ManageCategory from './components/Admin/Categories/ManageCategory';
 import AddOffering from './components/Partner/Offering/AddOffering';
 import EditOffering from './components/Partner/Offering/EditOffering';
 import AddSuccessStory from './components/Partner/SuccessStory/AddSuccessstory';
 import ManageAdmins from './components/Admin/Admins/ManageAdmin';
 import ManagePending from './components/Admin/Members/ManagePending/ManagePending';
 import ManageNotifications from './components/Admin/Members/ManageNotifications/ManageNotifications';
 import Reports from './components/Admin/Reports/Reports';
 import CompanyProfile from './components/Partner/Member/MemberProfile';
 import MyContentManagerAccount from './components/Partner/UserProfile/UserProfile';
 import ListOffering from './components/Partner/Offering/ListOffering';
 import ListSuccessStory from './components/Partner/SuccessStory/ListSuccessStory';
 
 import OfferingView from './components/Partner/Offering/ViewOffering'; 
 import ContentManager from './components/Partner/ContentManager/ContentManager';
 import EditContentManager from './components/Partner/ContentManager/EditUser';
 import EditSuccessStory from "./components/Partner/SuccessStory/EditSuccessStory";
 import SuccessStoryView from "./components/Partner/SuccessStory/ViewSuccessStory";
 import ViewCompanyProfile from "./components/Partner/Member/ViewCompanyProfile";
 import SignOut from './components/Signout/Signout';
 
  import MyAccount from './components/Admin/Profile/UserProfile';
 
 import SearchResult from './components/Users/SearchResult'
 import Industry_User from './components/Users/Industry';
 import Companies from './components/Users/Companies';
 import Productlist from './components/Users/Productlist';
 import SuccessStory from './components/Users/Casestudy';
 import CompanyDetails  from './components/Users/CompanyDetails';
 import ViewProduct from './components/Users/Productdetails';
 import ViewCaseStudy from './components/Users/StoryDetails';
 import Faq from './components/Users/common/Faq'
 import Contactus from './components/Users/common/Contactus'
 import Terms from './components/Users/common/Terms'
 import About from './components/Users/common/About';
 import Privacy from './components/Users/common/PrivacyPolicy'
 import Signup from './components/Users/common/Signup';
 import ServerError from './components/ErrorPages/ServerError'
 import NotFound from './components/ErrorPages/NotFound' */

 import './App.css'
 const Home = lazy(() => import( './components/HomePage/Home'))
 const Login = lazy(() => import( './components/LoginPage/Login'))
const ForgotPassword = lazy(() => import( './components/LoginPage/ForgotPassword'))
const ChangePassword = lazy(() => import( './components/LoginPage/ChangePassword'))
const Dashboard = lazy(() => import(  './components/Dashboard/Dashboard'))
 const DashboardAdmin = lazy(() => import(  './components/Dashboard/DashboardAdmin'))
 const ManageMember = lazy(() => import(  './components/Admin/Members/ManageMembers/ManageMembers'))
 const EditCompanyProfile = lazy(() => import(  './components/Admin/Members/MemberData/CompanyProfile'))
 const ManageContent = lazy(() => import(  './components/Admin/Members/ManageContent/ManageContent'))
 const ManagementDashboard = lazy(() => import(  './components/Admin/Members/ManageContent/ManagementDashboard'))
 const AnalyticsDashboard = lazy(() => import(  './components/Admin/Members/ManageContent/AnalyticsDashboard'))
 const ManageUsers = lazy(() => import(  './components/Admin/Members/ManageUsers/ManageUser'))
 const UserDetails = lazy(() => import( './components/Admin/Members/ManageUsers/UpdateUser')) 
 const ViewOffering = lazy(() => import(  './components/Admin/Preview/ViewOffering'))
 const ViewSuccessStory = lazy(() => import(  './components/Admin/Preview/ViewSuccessStory'))
 const ManageCategory = lazy(() => import(  './components/Admin/Categories/ManageCategory'))
 const AddOffering = lazy(() => import(  './components/Partner/Offering/AddOffering'))
 const EditOffering = lazy(() => import(  './components/Partner/Offering/EditOffering'))
 const AddSuccessStory = lazy(() => import(  './components/Partner/SuccessStory/AddSuccessstory'))
 const ManageAdmins = lazy(() => import(  './components/Admin/Admins/ManageAdmin'))
 const ManagePending = lazy(() => import(  './components/Admin/Members/ManagePending/ManagePending'))
 const ManageNotifications = lazy(() => import(  './components/Admin/Members/ManageNotifications/ManageNotifications'))
 const Reports = lazy(() => import(  './components/Admin/Reports/Reports'))
 const CompanyProfile = lazy(() => import(  './components/Partner/Member/MemberProfile'))
 const MyContentManagerAccount = lazy(() => import(  './components/Partner/UserProfile/UserProfile'))
 const ListOffering = lazy(() => import(  './components/Partner/Offering/ListOffering'))
 const ListSuccessStory = lazy(() => import(  './components/Partner/SuccessStory/ListSuccessStory')) 
 const OfferingView = lazy(() => import(  './components/Partner/Offering/ViewOffering')) 
 const ContentManager = lazy(() => import(  './components/Partner/ContentManager/ContentManager'))
 const EditContentManager = lazy(() => import(  './components/Partner/ContentManager/EditUser'))
 const EditSuccessStory = lazy(() => import(  "./components/Partner/SuccessStory/EditSuccessStory"))
 const SuccessStoryView = lazy(() => import(  "./components/Partner/SuccessStory/ViewSuccessStory"))
 const ViewCompanyProfile = lazy(() => import(  "./components/Partner/Member/ViewCompanyProfile"))
 const SignOut = lazy(() => import(  './components/Signout/Signout')) 
  const MyAccount = lazy(() => import(  './components/Admin/Profile/UserProfile')) 
 const SearchResult = lazy(() => import(  './components/Users/SearchResult'))
 const Industry_User = lazy(() => import(  './components/Users/Industry'))
 const Companies = lazy(() => import(  './components/Users/Companies'))
 const Productlist = lazy(() => import(  './components/Users/Productlist'))
 const SuccessStory = lazy(() => import(  './components/Users/Casestudy'))
 const CompanyDetails  = lazy(() => import(  './components/Users/CompanyDetails'))
 const ViewProduct = lazy(() => import(  './components/Users/Productdetails'))
 const ViewCaseStudy = lazy(() => import(  './components/Users/StoryDetails'))
 const Faq = lazy(() => import(  './components/Users/common/Faq'))
 const Contactus = lazy(() => import(  './components/Users/common/Contactus'))
 const Terms = lazy(() => import(  './components/Users/common/Terms'))
 const About = lazy(() => import(  './components/Users/common/About'))
 const Privacy = lazy(() => import(  './components/Users/common/PrivacyPolicy'))
 const Signup = lazy(() => import(  './components/Users/common/Signup'))
 const ServerError = lazy(() => import(  './components/ErrorPages/ServerError'))
 const NotFound = lazy(() => import(  './components/ErrorPages/NotFound')) 
 const trackId = 'G-N9Z1685WWD'

const  App =()=> {
  ReactGA.initialize(trackId);

  return (
    <div>   
    <Suspense fallback={<div>Loading...</div>}>
    <BrowserRouter>
    <div className="App">
   
      <Switch>

          <Route exact path="/" component ={Home}/>
          <Route path="/error" component = {ServerError} />
         
          <Route path="/login" component ={Login}/>
          <Route path="/aboutus" component ={About}/>
          <Route path="/signup" component ={Signup}/>
          <Route path="/faq" component ={Faq}/>
          <Route path="/termsandconditions" component ={Terms}/>
          <Route path="/contact" component ={Contactus}/>
          <Route path="/privacypolicy" component ={Privacy}/>
          <Route path="/industry" component ={Industry_User}/>  
         {/*  <Route path="/companies" component ={Companies}/>         
          <Route path="/companies/:id?" component ={CompanyDetails}/>
          <Route path="/successstories" component ={SuccessStory}/>
          <Route path="/successstories/:id?" component ={ViewCaseStudy}/>
          <Route path="/products/:id" component ={ViewProduct}/> 
          <Route path="/products" component ={Productlist}/>  */ }
 <Route path="/companies/:id?" render={ (props) => {
 
if (props.match.params.id == null) {
  return <Companies { ...props}/>
} else {
  return <CompanyDetails { ...props } />
}

} } />

<Route path="/products/:id?" render={ (props) => {
 
if (props.match.params.id == null) {
  return <Productlist { ...props} />
} else {
  return <ViewProduct { ...props } />
}

} } />

<Route path="/successstories/:id?" render={ (props) => {
 
if (props.match.params.id == null) {
  return <SuccessStory { ...props}/>
} else {
  return <ViewCaseStudy { ...props } />
}

} } />
       
          <Route path="/search" component ={SearchResult}/>
         
          <Route path="/signout" component={SignOut} />
          <Route path="/forgotPassword" component ={ForgotPassword}/>
          <Route path="/changePassword" component ={ChangePassword}/> 
            
          <Route path="/admindashboard" render={() => <DashboardAdmin type={"ADMIN"} />}/>                
          {/* <Route path="/admindashboard" component={DashboardAdmin}/>                 */}
          <Route path="/partnerdashboard" render={() => <DashboardAdmin type={"PARTNER"}/>}/>                
          <Route path="/myAccount" component={MyAccount}/>
          <Route path="/manageMember" component={ManageMember}/>
          <Route path="/profile/:id" component={EditCompanyProfile}/>
          <Route path="/manageContent" component={ManageContent}/>
          <Route path="/ManagementDashboard" component={ManagementDashboard}/>
          <Route path="/AnalyticsDashboard" component={AnalyticsDashboard}/>
          <Route path="/manageUsers" component={ManageUsers}/>
          <Route path="/userProfile:type:id" component={UserDetails}/> 
          <Route path="/offeringdetials/:id" component={ViewOffering}/>
          <Route path="/successstorydetails/:id" component={ViewSuccessStory}/>
          <Route path="/viewOffering:p:id" component={ViewOffering}/>
          <Route path="/viewSuccessStory:p:id" component={ViewSuccessStory}/>
          <Route path="/category" component={ManageCategory}/>
          <Route path="/manageAdmins" component={ManageAdmins}/>
          <Route path="/addOffering" component={AddOffering}/>
          <Route path="/pending" component={ManagePending}/>
          <Route path="/notifications" component={ManageNotifications}/>
          <Route path="/reports" component={Reports}/>

          {/* Partner Routes */}
          <Route path="/dashboard" component={Dashboard}/>
          <Route path="/company-profile-view" component={ViewCompanyProfile}/>
          <Route path="/company-profile" component={CompanyProfile}/>
          <Route path="/myprofile" component={MyContentManagerAccount}/>
          <Route path="/offerings" component={ListOffering}/>
          <Route path="/add-offering" component={AddOffering}/>
          <Route path="/edit-offering/:id" component={EditOffering}/>
          <Route path="/view-offering/:id" component={OfferingView}/>
          <Route path="/listsuccessstories" component={ListSuccessStory}/>
          <Route path="/add-successstory" component={AddSuccessStory}/>
          <Route path="/edit-successstory/:id" component={EditSuccessStory}/>
          <Route path="/view-successstory/:id" component={SuccessStoryView}/>
          <Route path="/contentmanagers" component={ContentManager}/>
          <Route path="/contentmanagers-profile/:id" component={EditContentManager} />
          <Route path="/500" component={ServerError} />          
          <Route component={NotFound} />
        </Switch>
    
    </div>
    </BrowserRouter>
   </Suspense>
   </div>
  );
}

export default App;
  
